import { Vue, Component } from 'vue-facing-decorator';
import { Plus } from '@element-plus/icons-vue'

@Component(
{
    components:
    {
        Plus
    },
    props:
    {
        windowWidth:
        {
            type: Number,
            required: true
        },
        windowHeight:
        {
            type: Number,
            required: true
        }
    }
})

export default class Footer extends Vue 
{
    private version = process.env.VUE_APP_VERSION;
}
