import { Vue, Component } from 'vue-facing-decorator';
import AuthService from '@/services/AuthService';
import Navigation from '@/components/Navigation/Navigation.vue';

@Component(
{
    props:
    {
        debug:
        {
            type: Boolean,
            required: false,
            default: false
        },
        windowWidth:
        {
            type: Number,
            required: true
        },
        windowHeight:
        {
            type: Number,
            required: true
        },
        showNavigation:
        {
            type: Boolean,
            required: false,
            default: true
        }
    },
    components:
    {
        Navigation
    },
})

export default class Header extends Vue 
{
    private environmentCode = '';
    private environmentVersion = '';

    public async mounted()
    {
        this.environmentCode = process.env.VUE_APP_ENV;
        this.environmentVersion = process.env.VUE_APP_VERSION;
    }

    public onLogoClick()
    {
        this.$router.push('/');
    }
}
