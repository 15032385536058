import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueCookies from 'vue3-cookies';
import enums from 'vue-enums'
import { useCookies } from "vue3-cookies";
import { useDark, useToggle } from '@vueuse/core';
import ElementPlus from 'element-plus';
import de from 'element-plus/dist/locale/de.mjs'
import 'element-plus/theme-chalk/dark/css-vars.css';
import 'element-plus/theme-chalk/index.css';
import 'aos/dist/aos.css';

if (useCookies().cookies.get('theme_dark') == 'true')
{
    useDark();
}

const app = createApp(App);
app.use(router);
app.use(enums);
app.use(VueCookies, {expireTimes: "1y", });
app.use(ElementPlus, {locale: de});

app.mount('#app');

