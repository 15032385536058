import { Vue, Component } from 'vue-facing-decorator';
import { QuestionFilled } from '@element-plus/icons-vue';
import AuthService from '@/services/AuthService';

@Component(
{
    props:
    {
        windowWidth:
        {
            type: Number,
            required: true
        },
        windowHeight:
        {
            type: Number,
            required: true
        },
        showHelp:
        {
            type: Boolean,
            required: false,
            default: true
        }
    },
    emits:
    [
        'onHelp'
    ],
    components:
    {
        QuestionFilled
    },
})

export default class DemoHeader extends Vue 
{
    private environmentCode = '';
    private environmentVersion = '';

    public async mounted()
    {
        this.environmentCode = process.env.VUE_APP_ENV;
        this.environmentVersion = process.env.VUE_APP_VERSION;
    }

    public onLogoClick()
    {
        this.$router.push('/');
    }
    
    public onHelpClick()
    {
        this.$emit('onHelp');
    }

}
