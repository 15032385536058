import { Vue, Component } from 'vue-facing-decorator';
import { ElMessage, ElNotification, ElMessageBox, MessageOptions } from 'element-plus';
import { CircleCheckFilled } from '@element-plus/icons-vue'
import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';

@Component(
{
    components:
    {
        Header,
        Footer,
        CircleCheckFilled
    },
})

export default class Presentation extends Vue 
{
    private windowWidth : number = window.innerWidth;
    private windowHeight : number = window.innerHeight;

    public async mounted()
    {
        window.addEventListener('resize', this.onWindowResize);
    }

    private onWindowResize(e : any)
    {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
    }
}
