enum NavigationIndex
{
    HOME            = "1",
    FOR_HEALTH_DEPT = "2",
    FOR_CANDIDATES  = "3",
    PARTICIPATION   = "4",
    DEMOVERSION     = "5",
    IMPRINT         = "6",
    TESTS           = "7",
}

export default NavigationIndex
