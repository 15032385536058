import { Component, Vue } from 'vue-facing-decorator';
import ApiService from '@/services/ApiService';

@Component(
{
    props:
    {
        questionid:
        {
            type: Number,
            required: true
        },
        list:
        {
            type: Object,
            required: false
        },
        windowWidth:
        {
            type: Number,
            required: true
        },
        windowHeight:
        {
            type: Number,
            required: true
        }
    },
    emits:
    [
        'onSelect'
    ],
    components:
    {
    }
})


export default class QuestionSelect extends Vue 
{
    private loading : boolean = true;

    public async mounted()
    {
    }

    private onQuestionClick(questionid : number)
    {
        this.$emit('onSelect', questionid);
    }
}
