import { Component, Vue } from 'vue-facing-decorator';

@Component(
{
    props:
    {
        helpIndex:
        {
            type: Number,
            required: true
        },
        questionCount:
        {
            type: Number,
            required: true
        },
        testMode:
        {
            type: Number,
            required: true
        },
        timeLimit:
        {
            type: Number,
            required: true
        },
        windowWidth:
        {
            type: Number,
            required: true
        },
        windowHeight:
        {
            type: Number,
            required: true
        }
    },
    components:
    {
    }
})


export default class Help extends Vue 
{
    public async mounted()
    {
    }
}
