import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1594b1be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "question"
}
const _hoisted_3 = { class: "question-title" }
const _hoisted_4 = { class: "answer-list" }
const _hoisted_5 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")
  const _component_CircleCheckFilled = _resolveComponent("CircleCheckFilled")
  const _component_el_icon = _resolveComponent("el-icon")
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 0.1 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.questionModel.questionid) + ".  ", 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 22 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.complexity == 0 ? _ctx.questionModel.singlechoice : _ctx.questionModel.question), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionList, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass('answer ' + (item.isselected ? 'selected' : '')),
                onClick: $event => (_ctx.onAnswerClick(item))
              }, [
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 1,
                      class: "icon-col"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_icon, { size: "32px" }, {
                          default: _withCtx(() => [
                            _withDirectives(_createVNode(_component_CircleCheckFilled, null, null, 512), [
                              [_vShow, item.isselected]
                            ])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_el_col, {
                      span: 20,
                      class: _normalizeClass('label-col' + ((_ctx.windowWidth < 892) ? '-mobile' : '-desktop'))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.option), 1)
                      ]),
                      _: 2
                    }, 1032, ["class"])
                  ]),
                  _: 2
                }, 1024)
              ], 10, _hoisted_5))
            }), 256))
          ])
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_loading, !_ctx.isReady]
  ])
}