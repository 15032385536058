import { Component, Vue } from 'vue-facing-decorator';
import { CircleCheckFilled } from '@element-plus/icons-vue';

@Component(
{
    props:
    {
        complexity:
        {
            type: Number,
            required: true
        },
        questionModel:
        {
            type: Object,
            required: true
        },
        optionList:
        {
            type: Array,
            required: true
        },
        windowWidth:
        {
            type: Number,
            required: true
        },
        windowHeight:
        {
            type: Number,
            required: true
        }
    },
    emits:
    [
        'onAnswer'
    ],
    components:
    {
        CircleCheckFilled
    }
})

export default class QuestionPanel extends Vue 
{
    private isReady : boolean = false;

    public async mounted()
    {
        this.isReady = true;
    }

    private clearSelection()
    {
        for (let o of this.optionList)
        {
            o.isselected = false;
        }
    }

    public onAnswerClick(option : any)
    {
        if (this.complexity == 0)
        {
            let isselected : boolean = option.isselected;
            this.clearSelection();
            option.isselected = !isselected;
        }

        if (this.complexity == 1)
        {
            option.isselected = !option.isselected;
        }

        this.$emit('onAnswer');
    }
}
