import { Vue, Component } from 'vue-facing-decorator';
import AuthService from '@/services/AuthService';
import NavigationIndex from '@/components/Navigation/EnumNavigationIndex';

@Component(
{
    props:
    {
        windowWidth:
        {
            type: Number,
            required: true
        },
        windowHeight:
        {
            type: Number,
            required: true
        },
        collapsed:
        {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components:
    {
    },
    enums:
    {
        NavigationIndex
    }
})

export default class Navigation extends Vue 
{
    private showMenu : boolean = false;
    private environmentCode = '';
    private environmentVersion = '';
    private navIndex : NavigationIndex = 0;

    public async mounted()
    {
        this.environmentCode = process.env.VUE_APP_ENV;
        this.environmentVersion = process.env.VUE_APP_VERSION;
        
        this.getCurrentRoute();
    }

    private getCurrentRoute()
    {
        switch (this.$route.name)
        {
            case "healthdepartments":
                this.navIndex = NavigationIndex.FOR_HEALTH_DEPT; 
                break;
            
            case "participants":
                this.navIndex = NavigationIndex.FOR_CANDIDATES; 
                break;
            
            case "tests":
                this.navIndex = NavigationIndex.TESTS; 
                break;
            
            case "demo":
                this.navIndex = NavigationIndex.DEMOVERSION; 
                break;
            
            
            case "terms":
                this.navIndex = NavigationIndex.IMPRINT; 
                break;
            
            default:
                this.navIndex = NavigationIndex.HOME; 
        };
    }

    public onMenuClick()
    {
        this.showMenu = true;
    }

    public onLinkClick(index : NavigationIndex)
    {
        switch (index)
        {
            case NavigationIndex.FOR_HEALTH_DEPT:
                this.$router.push(`/healthdepartments`);
                break;

            case NavigationIndex.FOR_CANDIDATES:
                this.$router.push(`/participants`);
                break;

            case NavigationIndex.TESTS:
                this.$router.push(`/tests`);
                break;

            case NavigationIndex.DEMOVERSION:
                this.$router.push(`/demo`);
                break;

            case NavigationIndex.IMPRINT:
                this.$router.push(`/terms`);
                break;

            default:
                this.$router.push(`/`);
                break;
        };
    }
}
