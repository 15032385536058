import { Vue, Component } from 'vue-facing-decorator';
import { Ticket, Clock, DocumentChecked, QuestionFilled } from '@element-plus/icons-vue';
import AuthService from '@/services/AuthService';

@Component(
{
    props:
    {
        windowWidth:
        {
            type: Number,
            required: true
        },
        windowHeight:
        {
            type: Number,
            required: true
        },
        time:
        {
            type: Number,
            required: true
        },
        questions_answered:
        {
            type: Number,
            required: true
        },
        questions_total:
        {
            type: Number,
            required: true
        },
        token:
        {
            type: String,
            required: true
        },
        targetgroup:
        {
            type: String,
            required: true
        },
        testdate:
        {
            type: String,
            required: true
        },
        showTicket:
        {
            type: Boolean,
            required: false,
            default: true
        },
        isRunning:
        {
            type: Boolean,
            required: false
        },
        showHelp:
        {
            type: Boolean,
            required: false
        },
        showFinish:
        {
            type: Boolean,
            required: false
        }
    },
    emits:
    [
        'onHelp',
        'onFinish'
    ],
    components:
    {
        Ticket,
        Clock,
        DocumentChecked,
        QuestionFilled
    },
})

export default class TestHeader extends Vue 
{
    public async mounted()
    {
    }

    public onHelpClick()
    {
        this.$emit('onHelp');
    }

    public onFinishClick()
    {
        this.$emit('onFinish');
    }
}
